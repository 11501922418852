"use client";
import Link from "next/link";
import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useParams } from "next/navigation";
import { useTranslations } from "next-intl";

const BurgerMenu = ({ pathname = "/" }) => {
  const { locale } = useParams();
  const t = useTranslations("navbar");
  const currentLocale = locale || "en";

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSubmenuVisible, setSubmenuVisible] = useState(false);
  const [isSubmenuVisible1, setSubmenuVisible1] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
    if (!state.isOpen) {
      setSubmenuVisible(false);
      setSubmenuVisible1(false);
    }
  };

  const toggleSubmenu = () => {
    setSubmenuVisible(!isSubmenuVisible);
    setSubmenuVisible1(false);
  };

  const toggleSubmenu1 = () => {
    setSubmenuVisible1(!isSubmenuVisible1);
    setSubmenuVisible(false);
  };

  const ResourcesDropdown = () => (
    <ul
      className={`nav_subul mobile_subnavburger ${
        isSubmenuVisible ? "showSubmenu" : "hidesubmenu"
      }`}
    >
      <li>
        <Link
          href={`/${currentLocale}/products/mechanical`}
          onClick={() => {
            setMenuOpen(false);
            setSubmenuVisible(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                pathname === `/${currentLocale}/products/mechanical`
                  ? "currentPagedropdown"
                  : ""
              }
            >
              {t("mechanical")}
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={`/${currentLocale}/products/electrical`}
          onClick={() => {
            setMenuOpen(false);
            setSubmenuVisible(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                pathname === `/${currentLocale}/products/electrical`
                  ? "currentPagedropdown"
                  : ""
              }
            >
              {t("electrical")}
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={`/${currentLocale}/products/water`}
          onClick={() => {
            setMenuOpen(false);
            setSubmenuVisible(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                pathname === `/${currentLocale}/products/water`
                  ? "currentPagedropdown"
                  : ""
              }
            >
              {t("water")}
            </span>
          </p>
        </Link>
      </li>
    </ul>
  );

  const ServicesDropdown = () => (
    <ul
      className={`nav_subul mobile_subnavburger ${
        isSubmenuVisible1 ? "showSubmenu" : "hidesubmenu"
      }`}
    >
      <li>
        <Link
          href={`/${currentLocale}/services/consulting`}
          onClick={() => {
            setMenuOpen(false);
            setSubmenuVisible1(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                pathname === `/${currentLocale}/services/consulting`
                  ? "currentPagedropdown"
                  : ""
              }
            >
              {t("consulting")}
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={`/${currentLocale}/services/waterparametertesting`}
          onClick={() => {
            setMenuOpen(false);
            setSubmenuVisible1(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                pathname === `/${currentLocale}/services/waterparametertesting`
                  ? "currentPagedropdown"
                  : ""
              }
            >
              {t("waterparametertesting")}
            </span>
          </p>
        </Link>
      </li>
    </ul>
  );

  return (
    <Menu
      left
      isOpen={isMenuOpen}
      onStateChange={handleStateChange}
      customCrossIcon={<CloseIcon sx={{ color: "#226DB4" }} />}
      customBurgerIcon={<MenuIcon sx={{ color: "#226DB4" }} />}
    >
      <div className="nav_container_right nav_container_rightBurger">
        <Link
          href={`/${currentLocale}`}
          onClick={() => {
            setMenuOpen(false);
            setSubmenuVisible(false);
            setSubmenuVisible1(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                pathname === `/${currentLocale}` || pathname === `/`
                  ? "currentPage"
                  : ""
              }
            >
              {t("title1")}
            </span>
          </p>
        </Link>

        <div
          className={`navbar_mobile withsubmenunav ${
            isSubmenuVisible ? "showSubmenu" : ""
          }`}
        >
          <Link href={`/${currentLocale}/products`}>
            <p className="navbar_mobile">
              <span
                onClick={toggleSubmenu}
                className={
                  pathname.startsWith(`/${currentLocale}/products`)
                    ? "currentPage"
                    : ""
                }
              >
                {t("title2")} <KeyboardArrowDownIcon />
              </span>
            </p>
          </Link>
          <ResourcesDropdown />
        </div>

        <div
          className={`navbar_mobile withsubmenunav ${
            isSubmenuVisible1 ? "showSubmenu" : ""
          }`}
        >
          <Link href={`/${currentLocale}/services`}>
            <p className="navbar_mobile">
              <span
                onClick={toggleSubmenu1}
                className={
                  pathname.startsWith(`/${currentLocale}/services`)
                    ? "currentPage"
                    : ""
                }
              >
                {t("title3")} <KeyboardArrowDownIcon />
              </span>
            </p>
          </Link>
          <ServicesDropdown />
        </div>

        <Link
          href={`/${currentLocale}/technologies`}
          onClick={() => {
            setMenuOpen(false);
            setSubmenuVisible(false);
            setSubmenuVisible1(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                pathname === `/${currentLocale}/technologies`
                  ? "currentPage"
                  : ""
              }
            >
              {t("title4")}
            </span>
          </p>
        </Link>

        <Link
          href={`/${currentLocale}/references`}
          onClick={() => {
            setMenuOpen(false);
            setSubmenuVisible(false);
            setSubmenuVisible1(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                pathname === `/${currentLocale}/references` ? "currentPage" : ""
              }
            >
              {t("title5")}
            </span>
          </p>
        </Link>

        <Link
          href={`/${currentLocale}/contact-us`}
          onClick={() => {
            setMenuOpen(false);
            setSubmenuVisible(false);
            setSubmenuVisible1(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                pathname === `/${currentLocale}/contact-us` ? "currentPage" : ""
              }
            >
              {t("title6")}
            </span>
          </p>
        </Link>
      </div>
    </Menu>
  );
};

export default BurgerMenu;

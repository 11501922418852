/** @format */

"use client";
import React, { useEffect, useState } from "react";
import "./Footer.css";
import { apiService } from "@/services/api.services";
import Link from "next/link";
import Image from "next/image";
import Fblogo from "../../Helper/images/fb.png";
import Xlogo from "../../Helper/images/x.png";
import { useTranslations, useLocale } from 'next-intl';

const Footer = () => {
  const [footerData, setFooterData] = useState(null);
  const [error, setError] = useState(null);
  const t = useTranslations('footer');
  const locale = useLocale();

  useEffect(() => {
    async function fetchFooterData() {
      try {
        const resp = await apiService.getAllUtilityData();
        if (resp.status === 200) {
          setFooterData(resp.data?.data?.attributes);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        setError(err);
      }
    }

    fetchFooterData();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const footerLogoUrl = footerData?.footer_logo?.data?.attributes?.url;

  // Determine the rights_reserved, email, and address based on the selected locale
  const rightsReserved = locale === 'cz' ? footerData?.rights_reserved_cz : footerData?.rights_reserved;
  const email = locale === 'cz' ? footerData?.email_cz : footerData?.email;
  const address = locale === 'cz' ? footerData?.address_cz : footerData?.address;

  return (
    <footer className="footer_area ">
      <div className="container">
        <div className="footer_container">
          <div className="footer_content_container">
            <div className="">
              <h4 className="footer_heading">{t('abouttitle')}</h4>
              <ul className="footer_nav_area footer_nav_areaout footcontent">
                <li className="footer_text about_txt">{t('about')}</li>
              </ul>
            </div>
            <div className="">
              <h4 className="footer_heading">{t('producttitle')}</h4>
              <ul className="footer_nav_area">
                <li className="footer_text" key={"mechanical"}>
                  <Link href={`/${locale}/products/mechanical`}>
                    {t('mechproduct')}
                  </Link>
                </li>
                <li className="footer_text" key={"electrical"}>
                  <Link href={`/${locale}/products/electrical`}>
                    {t('electricproduct')}
                  </Link>
                </li>
                <li className="footer_text" key={"water"}>
                  <Link href={`/${locale}/products/water`}>
                    {t('waterproduct')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <h4 className="footer_heading">{t('servicetitle')}</h4>
              <ul className="footer_nav_area">
                <li className="footer_text" key={"consulting"}>
                  <Link href={`/${locale}/services/consulting`}>
                    {t('consulting')}
                  </Link>
                </li>
                <li className="footer_text" key={"water-testing"}>
                  <Link href={`/${locale}/services/waterparametertesting`}>
                    {t('waterparameter')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <h4 className="footer_heading">{t('resourcestitle')}</h4>
              <ul className="footer_nav_area">
                <li className="footer_text" key={"terms-and-conditions"}>
                  <Link href={`/${locale}/terms-and-conditions`}>
                    {t('termsconsdition')}
                  </Link>
                </li>
                <li className="footer_text" key={"privacy-policy"}>
                  <Link href={`/${locale}/privacy-policy`}>
                    {t('privacypolicy')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_logDetails">
            {footerLogoUrl && (
              <Link href={`/${locale}/`} key={"logo"}>
                <Image
                  src={footerLogoUrl}
                  alt="Footer Logo"
                  width={158}
                  height={51}
                  className="nav_logo"
                />
              </Link>
            )}
            <p>{rightsReserved}</p>
           
            {/* <div className="footer_icons">
              <Link href={`/${locale}/`} key={"Fblogo"}>
                <Image
                  src={Fblogo}
                  alt="logo"
                  width={8}
                  height={18}
                  // className="nav_logo"
                />
              </Link>
              <Link href={`/${locale}/`} key={"Xlogo"}>
                <Image
                  src={Xlogo}
                  alt="logo"
                  width={19}
                  height={19}
                  // className="nav_logo"
                />
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { config } from "@/Helper/config";
import axios from "axios";

const getAllUtilityData = () => {
  return axios.get(`${config.strapiAPi}/api/utility?populate=*`);
};

const postContactUsDetails = ({ data }) => {
  return axios.post(`${config.strapiAPi}/api/contact-uses`, { data });
};

const sendThankyouMail = ({ data }) => {
  return axios.post(`${config.strapiAPi}/api/email`, data);
};

const getRefreshAccessToken = async () => {
  try {
    const response = await axios.post(`${config.strapiAPi}/zoho/token`);
    return response.data;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw error;
  }
};

export const createLead = async (leadData, newAccessToken) => {
  console.log("Sending lead data:", leadData);
  try {
    const response = await axios.post(
      `${config.strapiAPi}/zoho/lead`,
      {
        data: [
          {
            Last_Name: leadData.last_name,
            Email: leadData.email,
            Phone: leadData.phone_number,
            Description: leadData.description,
          },
        ],
      },
      {
        headers: {
          Authorization: `Zoho-oauthtoken ${newAccessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error creating lead:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

const getAllProducts = () => {
  return axios.get(`${config.strapiAPi}/api/products?populate=*`);
};

const getAllCategoriesWithProducts = () => {
  return axios.get(
    `${config.strapiAPi}/api/categories?populate=image,products`
  );
};

const getProductDetailsBySubcategoryId = (subcategoryId) => {
  return axios.get(`${config.strapiAPi}/api/productdetails`, {
    params: {
      filters: {
        subcategory: {
          id: {
            $eq: subcategoryId,
          },
        },
      },
      populate: {
        image: "*",
        subcategory: {
          populate: "category",
        },
      },
    },
  });
};

const getSubCategoriesWithProducts = (categoryId) => {
  return axios.get(`${config.strapiAPi}/api/subcategories`, {
    params: {
      populate: "category,image,products",
      "filters[category][id][$eq]": categoryId,
    },
  });
};

export const apiService = {
  getAllUtilityData,
  postContactUsDetails,
  sendThankyouMail,
  getRefreshAccessToken,
  createLead,

  // new product functions
  getAllProducts,
  getAllCategoriesWithProducts,
  getSubCategoriesWithProducts,
  getProductDetailsBySubcategoryId,
};

"use client";
import "./NavBar.css";
import React, { useEffect, useState, useCallback } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter, usePathname, useSearchParams,useParams } from "next/navigation";
import { Snackbar } from "@mui/material";
import { apiService } from "@/services/api.services";
import BurgerMenu from "./BurgerMenu";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslations } from "next-intl";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 72,
  height: 30,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#fff",
        border: `1px solid ${theme.palette.mode === "light" ? "#8DC63F" : "#8DC63F"}`, 
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#39393D",
    border: `1px solid ${theme.palette.mode === "light" ? "#8DC63F" : "#8DC63F"}`, 
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const NavBar = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { locale } = useParams();
  const t = useTranslations("navbar");

  const [isOnScroll, setIsOnScroll] = useState(false);
  const [logo, setLogo] = useState(null);
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsOnScroll(window.scrollY >= 100);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const resp = await apiService.getAllUtilityData();
        if (resp.status === 200) {
          setLogo(resp.data?.data?.attributes?.logo?.data?.attributes?.url);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };
    fetchLogo();
  }, []);

  const handleLanguageToggle = useCallback(() => {
    const URLPath = `${locale === "en"
      ? `/cz${pathname.replace("/en", "")}?${searchParams.toString()}`
      : `/en${pathname.replace("/cz", "")}?${searchParams.toString()}`
      }`;
    router.push(URLPath);
  }, [locale, pathname, searchParams, router]);

  const ResourcesDropdown = useCallback(() => (
    <ul className="nav_subulweb nav_subul ResourcesDropdown productresource">
      {["mechanical", "electrical", "water"].map((key) => (
        <li key={key}>
          <Link href={`/${locale}/products/${key}`}>
            <p className="navbar_desktop">
              <span
                className={
                  pathname === `/${locale}/products/${key}`
                    ? "currentPagedropdown"
                    : ""
                }
              >
                {t(key)}
              </span>
            </p>
          </Link>
        </li>
      ))}
    </ul>
  ), [locale, pathname, t]);

  const ServiceDropdown = useCallback(() => (
    <ul className="nav_subulweb nav_subul ResourcesDropdown productresource">
      {["consulting", "waterparametertesting"].map((key) => (
        <li key={key}>
          <Link href={`/${locale}/services/${key}`}>
            <p className="navbar_desktop">
              <span
                className={
                  pathname === `/${locale}/services/${key}`
                    ? "currentPagedropdown"
                    : ""
                }
              >
                {t(key)}
              </span>
            </p>
          </Link>
        </li>
      ))}
    </ul>
  ), [locale, pathname, t]);

  return (
    <div
      className={`nav_area ${isOnScroll ? "onscroll_bg" : ""} ${pathname === "/" || pathname === "/features" || pathname === "/pricing"
        ? "nav_area_home"
        : "nav_area_blog"
        }`}
    >
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={isOpen}
        autoHideDuration={3000}
        onClose={() => setisOpen(false)}
        message="Coming Soon!"
      />
      <div className="container navcontainer">
        <nav className="nav_container">
          <div className="nav_container_left nav_leftimg">
            {logo && (
              <Link href={`/${locale}/`} key={"logo"}>
                <Image
                  src={logo}
                  alt="logo"
                  width={130}
                  height={72}
                  className="nav_logo"
                  priority
                />
              </Link>
            )}
          </div>
          <div className="nav_container_right">
            <div className="nav_container_right_inner">
              <Link
                href={`/${locale}/`}
                key={"home"}
                aria-label={`home`}
              >
                <p className="navbar_desktop">
                  <span
                    className={
                      pathname === `/${locale}` || pathname === `/`
                        ? "currentPage"
                        : ""
                    }
                  >
                    {t("title1")}
                  </span>
                </p>
              </Link>

              <div className="navbar_desktop withsubmenunav">
                <span
                  className={
                    pathname === `/${locale}/products` ||
                      pathname?.includes(`/${locale}/products`)
                      ? "currentPagedropdown"
                      : ""
                  }
                >
                  <Link href={`/${locale}/products`}>
                    {t("title2")} <KeyboardArrowDownIcon />
                  </Link>
                </span>
                <ResourcesDropdown />
              </div>

              <div className="navbar_desktop withsubmenunav">
                <span
                  className={
                    pathname === "/services" ||
                      pathname?.includes(`/${locale}/services`)
                      ? "currentPagedropdown"
                      : ""
                  }
                >
                  <Link href={`/${locale}/services`}>
                    {t("title3")} <KeyboardArrowDownIcon />
                  </Link>
                </span>
                <ServiceDropdown />
              </div>

              <Link
                href={`/${locale}/technologies`}
                key={"technologies"}
                aria-label={t("title4")}
              >
                <p className="navbar_desktop">
                  <span
                    className={
                      pathname === `/${locale}/technologies`
                        ? "currentPage"
                        : ""
                    }
                  >
                    {t("title4")}
                  </span>
                </p>
              </Link>
              <Link href={`/${locale}/references`}
               key={"references" }
               aria-label={t("title5")}>
                <p className="navbar_desktop">
                  <span
                    className={
                      pathname === `/${locale}/references`
                        ? "currentPage"
                        : ""
                    }
                  >
                    {t("title5")}
                  </span>
                </p>
              </Link>

              <Link href={`/${locale}/contact-us`} key={"contact-us"}
              aria-label={t("title6")}>
                <p className="navbar_desktop">
                  <span
                    className={
                      pathname === `/${locale}/contact-us`
                        ? "currentPage"
                        : ""
                    }
                  >
                    {t("title6")}
                  </span>
                </p>
              </Link>
            </div>
          </div>
          <div className="Nav_containre_rightside">
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={locale === "cz"}
                    onChange={handleLanguageToggle}
                    aria-label="Language Toggle"
                  />
                }
      
              />
            </FormGroup>
          </div>
        </nav>
        <BurgerMenu pathname={pathname} />
      </div>
    </div>
  );
};

export default NavBar;
